<template>
  <div class="card-main card-main-map">
    <b-skeleton class="mb-2" width="100%" height="145px"></b-skeleton>
    <div
      class="d-flex flex-row align-items-center justify-content-center gap-5 my-2"
    >
      <b-skeleton class="mr-3" width="100%" height="30px"></b-skeleton>
      <b-skeleton width="30px" height="30px"></b-skeleton>
    </div>
    <b-skeleton class="my-2" width="100%" height="30px"></b-skeleton>
    <b-skeleton class="my-2" width="100%" height="30px"></b-skeleton>
    <b-skeleton class="my-2" width="100%" height="30px"></b-skeleton>
    <b-skeleton class="my-2" width="100%" height="30px"></b-skeleton>
  </div>
</template>

<script>
export default {
  name: "CardLoading",
};
</script>

<style lang="scss" scoped>
.card-main {
  width: 330px;
  height: 380px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 1px 1px 4px 1px #00000033;
  padding: 12px;

  &hover {
    box-shadow: 1px 1px 4px 1px #5d5d5dba;
  }
}
</style>

<template>
  <div class="header">
    <div class="header-main">
      <h3>{{ title }}</h3>
      <div class="d-flex">
        <div class="name">
          {{ dataUser.nome }}
        </div>
        <span class="mx-2"> | </span>
        <div class="company">{{ dataUser.razao_social }}</div>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: {
    title: {
      type: String,
      default: "Header",
    },
  },
  data() {
    return {
      dataUser: {},
    };
  },
  async mounted() {
    const data = await this.$session.get("client");
    if (data) {
      this.dataUser = {
        nome: data.nome,
        razao_social: data.razao_social,
      };
    }
    // try {
    //   axios.get("user").then((response) => {
    //     this.responseDataEnterprises = response.data;
    //   });
    // } catch (error) {
    //   console.error("Erro ao fazer requisição de aceitos:", error);
    // }
  },
};
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  min-height: 72px;
  max-height: 220px;
  background-color: $background;
  padding-top: 8px;
  p {
    margin-bottom: 0.4rem;
  }
}
.header-main {
  display: flex;
  justify-content: space-between;
  height: 100%;
  h3 {
    color: $neutral-95;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
  }
}

.name {
  height: 22px;
  flex-grow: 0;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #212529;
}

.company {
  height: 24px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 0;
}
</style>

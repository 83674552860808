<template>
  <div class="d-flex h-100 w-100">
    <b-skeleton class="mb-2" width="100%" height="100%"></b-skeleton>
  </div>
</template>

<script>
export default {
  name: "MapLoading",
};
</script>

<style lang="scss" scoped></style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue } from 'bootstrap-vue'
import 'leaflet/dist/leaflet.css';
import 'leaflet-minimap/dist/Control.MiniMap.min.css';
import 'leaflet-minimap/dist/Control.MiniMap.min.js';
import 'hooper/dist/hooper.css';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'

import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import LControlPolylineMeasure from 'vue2-leaflet-polyline-measure';
import { firebase } from '@firebase/app';
import 'firebase/analytics';
import VueSession from 'vue-session'
import axios from 'axios'


const firebaseConfig = {
    apiKey: process.env.VUE_APP_API_KEY,
    authDomain: process.env.VUE_APP_KEY_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_KEY_DATABASE_URL,
    projectId: process.env.VUE_APP_PROJECT_ID,
    storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_ID,
    measurementId: process.env.VUE_APP_MEASUREMENT_ID
}


// Initialize Firebase
firebase.initializeApp(firebaseConfig);
Vue.prototype.$analytics = firebase.analytics();

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(VueLodash, { lodash: lodash })
Vue.use(VueSession)


axios.interceptors.request.use(async config => {
    if (firebase.auth().currentUser) {
        config.headers.token = await firebase.auth().currentUser.getIdToken()
    }else{
        this.$router.push("/");
    }
    return config
}, (error) => {
    console.log(error)
    return Promise.reject(error)
})



Vue.component('l-control-polyline-measure', LControlPolylineMeasure);

document.title = "Vitrine"


let app;

firebase.auth().onAuthStateChanged(async user => {
    if (!app) {
        //wait to get user
        var user = await firebase.auth().currentUser;
        //start app
        app = new Vue({
          router,
          created() {
            //redirect if user not logged in
            if (!user) {
              this.$router.push("/");
            }
          },
        store,
        render: h => h(App)
        }).$mount("#app");
      }
    });
<template>
  <div
    :class="{ 'card-main': !formap, 'card-main-map': formap }"
  >
    <Carousel :images="data.imagens" />
    <div @click="onClickCard" style="cursor: pointer;">
      <div class="type">
        <h6>{{ data.nome_empreendimento }}</h6>
        <i
          :class="data.salvo ? 'icon icon-mark-saved' : 'icon icon-mark'"
          @click.stop.prevent="
            handleSavedVenture({
              id: data._id,
              status: data.salvo,
            })
          "
        ></i>
      </div>
      <span style="color: #001b74">{{ data.tipo_empreendimento ? data.tipo_empreendimento[0] : ' ' }}</span
      ><br />
      <div class="address">
        <i class="icon icon-geo"></i>
        <span>{{ `${data.bairro} - ${data.cidade}, ${data.estado}` }}</span>
      </div>
      <div class="item-label">
        <span>Porte Incorporadora</span>
        <strong>{{ data.porte_incorporadora }}</strong>
      </div>

      <div class="item-label">
        <span>Custo estimado</span>
        <strong>{{ currencyFormat(data.custo_estimado_reais) }}</strong>
      </div>

      <div class="item-label">
        <span>VGV</span>
        <strong>{{ currencyFormat(data.vgv_reais) }}</strong>
      </div>

      <div class="item-label">
        <span>Unidades vendidas </span>
        <strong>{{ `${Math.round((data.unidades_vendidas / data.estoque) * 100 )}%` }}</strong>
      </div>

      <div class="item-label">
        <span>Ticket médio</span>
        <strong>{{ currencyFormat(data.ticket_medio) }}</strong>
      </div>
    </div>
  </div>
</template>

<script>
import Carousel from "./Carousel.vue";
import { generalMixins } from "../mixins/generalMixins";
export default {
  mixins: [generalMixins],
  components: {
    Carousel,
  },
  props: {
    data: {
      type: Object,
    },
    formap: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {
    onClickCard() {
      this.$router.push({
        name: "enterpriseDetail",
        params: {
          id: this.data._id,
          data: this.data,
          status: this.status,
          comesFrom: this.$route.meta.name
        },
      });
    },
    handleSavedVenture(idVenture) {

      this.$emit(
        this.formap ? "grandChildreSaveVenture" : "emitSaveVenture",
        idVenture
      );
    },
  },
  computed: {
    imagesLinks() {
      return this.data.imagens.map((imagem) => imagem.link);
    },
    currencyFormat() {
      return (value) => {
        if (!value) return "";
        return new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(value);
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.card-main {
  width: 324px;
  height: 380px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 1px 1px 4px 1px #00000033;
  padding: 12px;

  img {
    border-radius: 6px;
  }

  &hover {
    box-shadow: 1px 1px 4px 1px #5d5d5dba;
  }
}

.icon {
  display: inline-block;
  width: 22px;
  height: 22px;
}

.icon-mark {
  background: url("../assets/mark.svg") center/cover;
  color: $neutral-95;
  cursor: pointer;
}

.icon-mark-saved {
  background: url("../assets/mark-saved.svg") center/cover;
  color: $neutral-95;
  cursor: pointer;
}

.icon-geo {
  background: url("../assets/geo.svg") center/cover;
  width: 19px;
  height: 19px;
  margin-right: 4px;
}

.type {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding-top: 8px;

  h6 {
    font-size: 16px;
    font-weight: 600;
    color: $main-blue;
  }
}
/deep/.leaflet-popup-content {
  width: 230px !important;
}
.address {
  display: flex;
  align-items: stretch;
  align-content: center;
  justify-content: flex-start;
  color: $neutral-80;
  // font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin: 4px 0;
}

.item-label {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;

  span {
    color: $neutral-70;
  }

  strong {
    color: $neutral-95;
    font-weight: 600;
  }
}
.card-main-map {
  /deep/.hooper-list {
    img {
     width: 256px !important;
    }
  }
    
}
</style>

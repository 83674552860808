<template>
  <b-nav vertical class="sidebar">
    <div class="tools">
      <div class="logo">
        <img width="37" src="@/assets/logo-sidebar.svg" alt="logo-vitrine" />
      </div>
      <div v-if="showBar" class="mt-4">
        <b-nav vertical v-if="visao_usuario === 'fundo_investimento'">
          <router-link to="/search"
            ><i class="icon icon-search"></i
          ></router-link>
          <router-link to="/enterprises"
            ><i class="icon icon-building"></i
          ></router-link>
          <router-link to="/users"><i class="icon icon-user"></i></router-link>
        </b-nav>
        <b-nav vertical v-if="visao_usuario === 'incorporadora'">
          <router-link to="/enterprises"
            ><i class="icon icon-building"></i
          ></router-link>
          <router-link to="/users"><i class="icon icon-user"></i></router-link>
        </b-nav>
      </div>
    </div>
    <logout redirect="/vitrine"></logout>
  </b-nav>
</template>

<script>
import Logout from "@/components/ui/Logout.vue";
import Search from "@/views/Search.vue";
import { SearchIcon, LogOutIcon, XCircleIcon, XIcon } from "vue-feather-icons";
import axios from "axios";
export default {
  name: "AppSidebar",
  components: {
    SearchIcon,
    LogOutIcon,
    XCircleIcon,
    XIcon,
    Logout,
    Search,
  },
  data() {
    return {
      pinSet: false,
      keyComponent: 0,
      visao_usuario: null,
      showBar: false,
    };
  },
  async mounted() {
    const currentUser = await this.$session.get("client");
    this.visao_usuario = currentUser.visao_usuario;
    if(!this.visao_usuario) {
      this.$router.push({ name: "Login" });
    
    }
    this.showBar = true;
  },
  methods: {
    logout: function () {
      this.$analytics.logEvent("logout", {
        method: "Google",
        event_category: "logout",
      });
      this.$session.destroy();
      firebase.auth().signOut();
      this.$router.push({ redirect: (window.location.href = "/vitrine") });
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200");

* {
  font-family: "Source Sans Pro", sans-serif;
}
.sidebar {
  background: $main-blue;
  width: 52px;
  height: 100vh;
  a {
    color: #fff !important;
  }
}
.icon {
  display: inline-block;
  width: 21px;
  height: 21px;
}
.icon-search {
  background: url("../assets/search.svg") center/cover;
}
.icon-building {
  background: url("../assets/building.svg") center/cover;
}
.icon-graph {
  background: url("../assets/graph.svg") center/cover;
}
.icon-user {
  background: url("../assets/user.svg") center/cover;
}
.nav {
  flex-wrap: unset;
  align-items: center;
  a {
    display: flex;
    width: 52px;
    height: 59px;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-out 0.1s;
  }
}
/deep/.router-link-active {
  color: #fff;
  background-color: $blue-light;
  transition: all 0.3s ease-out;
}
/deep/.nav-item {
  display: grid;
  justify-items: start;
  align-items: center;
}
.logo {
  display: grid;
  align-items: center;
  justify-content: center;
  height: 95px;
  width: 50px;
  margin: 7px 0;
  padding-bottom: 18px;
}
.sidebar-action {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  color: $white;
}
#logout {
  position: absolute;
  bottom: 20px;
}
#logout {
  cursor: pointer;
}
</style>

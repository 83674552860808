<template>
  <hooper class="hooper-container" :settings="hooperSettings">
    <slide v-show="images.length > 0"  v-for="(photo, index) in images" :key="index">
      <img  class="image" :src="photo.link" alt="Foto empreendimentos" />
    </slide>
    <slide v-show="!photo">
      <img
        class="image"
        src="https://t3.ftcdn.net/jpg/05/06/06/68/360_F_506066800_vTsPUadAyBci2bStUAjWYGTKOzGYkMAP.jpg"
        alt="Imagem 1"
      />
    </slide>
    <hooper-pagination
      class="hooper-addons"
      v-if="images.length > 0"
      slot="hooper-addons"
    >
      <div slot="hooper-addons">
        <button>X</button>
      </div>
    </hooper-pagination>
    <hooper-navigation
      class="hooper-addons"
      v-if="images.length > 0"
      slot="hooper-addons"
    >
      <div class="Left" slot="hooper-prev">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            fill="#868E96"
          />
        </svg>
      </div>

      <div class="Left" slot="hooper-next">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
            fill="#868E96"
          />
        </svg>
      </div>
    </hooper-navigation>
  </hooper>
</template>

<script>
import {
  Hooper,
  Slide,
  Navigation as HooperNavigation,
  Pagination as HooperPagination,
} from "hooper";

export default {
  name: "Carousel",
  components: {
    Hooper,
    Slide,
    HooperNavigation,
    HooperPagination,
  },
  props: {
    images: {
      type: Array,
    },
  },
  data() {
    return {
      hooperSettings: {
        itemsToShow: 1,
        centerMode: true,
        wheelControl: false,
      },
    };
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.image {
  width: 306px;
  height: 136px;
  margin: -1px;
  display: block !important;
}
.hooper {
  height: 137px !important;
}

.Left {
  width: 30px;
  height: 30px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  padding: 7px;
  border-radius: 53px;
  background-color: rgba(255, 255, 255, 0.75);
}

.Ellipse-8 {
  width: 4px;
  height: 4px;
  flex-grow: 0;
  background-color: #fff;
}

.hooper-addons {
  display: none;
}

.hooper-container:hover {
  .hooper-addons {
    display: block;
  }
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";
import Application from "../templates/Application.vue";
import firebase from "firebase";
import Auth from "@/views/auth/index.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/search",
  },
  {
    path: "/application",
    name: "Application",
    meta: { requiresAuth: true },
    component: Application,
    children: [
      {
        path: "/search",
        meta: {
          name: "search",
          title: "Search",
          dlContext: "vitrine",
        },
        beforeEnter: _beforeEnter,
        component: () => import("@/views/Search.vue"),
      },
      {
        path: "/enterprises",
        meta: {
          name: "enterprises",
          title: "Enterprises",
          dlContext: "vitrine",
        },
        beforeEnter: _beforeEnter,
        component: () => import("@/views/Enterprises.vue"),
      },
      {
        path: '/enterprise/:id',
        name: "enterpriseDetail",
        meta: {
          name: "enterpriseDetail",
          title: "Enterprise Detail",
          dlContext: "vitrine",
        },
        beforeEnter: _beforeEnter,
        component: () => import("@/views/enterprisesDetails/index.vue"),
      },
      {
        path: "/users",
        meta: {
          name: "users",
          title: "Users",
          dlContext: "vitrine",
        },
        beforeEnter: _beforeEnter,
        component: () => import("@/views/Users.vue"),
      },
    ],
  },
  {
    path: "",
    name: "Auth",
    component: Auth,
    children: [
      {
        path: "login",
        name: "Login",
        component: () => import("@/views/auth/Login.vue"),
      },
      {
        path: "/signup",
        name: "signup",
        meta: {
          name: "signup",
          title: "Signup",
          dlContext: "vitrine",
        },
        component: () => import("@/views/auth/signup/index.vue"),
      },
      {
        path: "forgot-password",
        name: "forgot-password",
        component: () => import("@/views/auth/Forgot-password.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

function _beforeEnter(to, from, next) {
  const isAuthenticated = firebase.auth().currentUser;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (requiresAuth && !isAuthenticated) {
    next("/login");
  } else if (to.path === "/login" && isAuthenticated) {
    next("/search");
  } else {
    next();
  }
  next();
}

export default router;

<template>
  <l-map :zoom="zoom" :center="center" :options="{ zoomControl: false }">
    <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
    <l-control-zoom position="bottomright"></l-control-zoom>
      <l-marker
        v-for="(data, index) in coordenades"
        :key="index"
        :lat-lng="[data.latitude, data.longitude]"
        :icon="icon"
        @click="getEnterprisePin(data._id || data.id)"
      >
        <l-popup :key="index">
          <Card
            v-if="isFound === 'fundo_investimento'"
            :key="indexCard"
            :formap="true"
            :data="enterprisePin"
            @grandChildreSaveVenture="fatherSaveVenture"
          />
          <card-emp :key="indexCard" v-else-if="isFound === 'incorporadora'" :formap="true" :data="enterprisePin" />

        </l-popup>
      </l-marker>
<!-- 
    <div>
      <l-marker
        v-for="(data, index) in coordenades"
        :key="index"
        :lat-lng="[data.latitude, data.longitude]"
        :icon="icon"
        @click="getEnterprisePin(data._id)"
      >
        <l-popup>
          <card-emp :formap="true" :data="enterprisePin" />
        </l-popup>
      </l-marker>
    </div> -->
    <vue-leaflet-minimap
      v-if="isminimap"
      :layer="layer"
      :options="options"
      :zoom-offset="zoomOffset"
      :width="widthminimap"
      :height="heightminimap"
      position="bottomleft"
    ></vue-leaflet-minimap>
  </l-map>
</template>

<script>
import L from "leaflet";
import VueLeafletMinimap from "vue-leaflet-minimap";
import { LMap, LTileLayer, LMarker, LControlZoom, LPopup } from "vue2-leaflet";
import Card from "./Card.vue";
import ImgPine from "../assets/icon-pin.svg";
import CardEmp from "./CardEmp.vue";

export default {
  name: "MapLeafletContent",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LControlZoom,
    VueLeafletMinimap,
    LPopup,
    Card,
    CardEmp,
  },
  props: {
    coordenades: {
      type: Array,
      required: true,
    },
    request: {
      type: [Object, Array],
      required: false,
    },
    isminimap: {
      type: Boolean,
      default: false,
    },
    widthminimap: {
      type: Number,
      default: 150,
    },
    heightminimap: {
      type: Number,
      default: 150,
    },
    positionminimap: {
      type: String,
      default: "bottomleft",
    },
  },
  data() {
    return {
      indexCard: 0,
      isFound: null,
      enterprisePin: null,
      center: [-23.5489, -46.6388],
      zoom: 11,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution: "© OpenStreetMap contributors",
      layer: new L.TileLayer(
        "http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      ),
      icon: L.icon({
        iconUrl: ImgPine,
        iconSize: [32, 37],
        iconAnchor: [16, 37],
      }),
      layers: [],
      options: {
        position: this.positionminimap,
        width: this.widthminimap,
        height: this.heightminimap,
      },
      zoomOffset: -3,
    };
  },
  mounted() {
    this.isFound = this.$session.get("master").visao_usuario;
    this.getEnterprisePin(this.coordenades[0]._id || this.coordenades[0].id);
    this.center = [this.coordenades[0].latitude, this.coordenades[0].longitude];
  },
  methods: {
    getEnterprisePin(id) {
      this.enterprisePin = null;
      this.indexCard++;
        this.enterprisePin = this.request.filter((fill) => fill._id === id)[0];
    },
    fatherSaveVenture(idVenture) {
      this.$emit("emitSaveVenture", idVenture);
    },
  },
};
</script>
<style lang="scss" scoped>
#map {
  height: 100%;
  width: 100%;
  color: #000;
}
/deep/.leaflet-popup-content {
  width: 230px !important;
}
</style>

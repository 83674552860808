<template>
  <div class="home-apps">
    <app-sidebar></app-sidebar>
    <app-content></app-content>
  </div>
</template>

<script>
import AppSidebar from "@/components/AppSidebar.vue";
import AppContent from "@/views/AppContent.vue";
export default {
  components: {
    AppSidebar,
    AppContent,
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap");

* {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.home-apps {
  display: grid;
  grid-template-columns: 52px 1fr;
}
.home {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
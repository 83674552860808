<template>
  <div class="container-search">
    <header-content title="Buscar Empreendimentos">
      <div class="header-slot-template">
        <div class="control-suface">
          <b-button class="mr-2 d-flex align-items-center" :disabled="!surfaceFull" @click="toggleSurface">
            <img v-if="!surfaceFull" class="icon" src="../assets/not-full.svg" alt="Icon Not Full" />
            <img v-else class="icon" src="../assets/not-full-black.svg" alt="Icon Not Full" />
          </b-button>
          <b-button class="d-flex align-items-center" :disabled="surfaceFull" @click="toggleSurface">
            <img v-if="surfaceFull" class="icon" src="../assets/full.svg" alt="Icon Full" />
            <img v-else class="icon" src="../assets/full-black.svg" alt="Icon Full" />
          </b-button>
        </div>
        <div class="buttons-filter-ordering">
          <PainelDrop :show="showdrop" @emitShowDrop="handleShowDrop" @emitSendFilter="handleFilter" />
          <select v-model="selectedSort" @change="handleSelectedSort" class="selectSort">
            <option disabled value="" selected>Ordenar por</option>
            <option v-for="option in ordering" v-bind:value="option.value" :key="option.value">
              {{ option.title }}
            </option>
          </select>
        </div>
      </div>
    </header-content>
    <div class="content-search" :class="{ 'content-full': surfaceFull }">
      <div class="map" v-if="!surfaceFull">
        <MapLoading v-if="onLoading" />
        <MapLeaflet v-if="!onLoading" :coordenades="coordinates" :request="requestdata" :isminimap="true"
          @emitSaveVenture="saveVenture" />
      </div>
      <div class="surface" :class="{ 'surface-full': surfaceFull }">
        <div v-for="index in 6" :key="index" v-if="onLoading">
          <CardLoading />
        </div>
        <div v-for="(data, index) in requestdata" :key="index" v-if="!onLoading">
          <Card :data="data" @emitSaveVenture="saveVenture" />
        </div>
      </div>
    </div>
    <div class="no-content" v-if="isNoContent">
      <div class="box-no-content">
        <img :src="content_noContent.icon" class="icon-no-content" alt="Icone" />
      </div>
      <div class="title-no-content">
        <span>{{ content_noContent.title }}</span>
      </div>
      <div class="description-no-content">
        <span>{{ content_noContent.description }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import HeaderContent from "@/components/Header.vue";
import Card from "@/components/Card.vue";
import data from "../dataMockup/dataMockup.json";
import MapLeaflet from "@/components/MapVueLeaflet.vue";
import ImgInterests from "../assets/icon-building-check.svg";
import PainelDrop from "@/components/PainelDropdown.vue";
import CardLoading from "@/components/loading/Card.vue";
import MapLoading from "@/components/loading/Map.vue";

export default {
  components: {
    HeaderContent,
    Card,
    MapLeaflet,
    PainelDrop,
    CardLoading,
    MapLoading,
  },
  data() {
    return {
      coordinates: [],
      requestdata: [],
      mockupdata: data,
      showModal: false,
      onLoading: false,
      isNoContent: false,
      surfaceFull: false,
      showdrop: false,
      selectedSort: "",
      saving: false,
      dataFilter: {
        region: "",
        state: "",
        city: "",
        neighborhood: "",
        typeEnterprise: [],
        activitySegmentation: [],
        sizeEnterprise: [],
        numbersUnits: [],
        unitsSold: [],
        phaseWork: [],
        generalProgress: [],
      },
      ordering: [
        { title: "Alfabética", value: 0 },
        { title: "Mais recente", value: 1 },
        { title: "Menos recente", value: 2 },
      ],
      content_noContent: {
        icon: ImgInterests,
        title: "Ainda não há empreendimentos que aceitaram o seu interesse",
        description:
          "Após a Incorporadora aceitar o seu interesse, o empreendimento irá aparecer aqui.",
      },
    };
  },
  mounted() {
    this.getSearchForEnterprises();
  },
  methods: {
    handleShowDrop(value) {
      this.showdrop = value;
    },
    handleShow() {
      this.showModal = !this.showModal;
    },
    toggleSurface() {
      this.surfaceFull = !this.surfaceFull;
    },
    setFilter() {
      const queryParams = new URLSearchParams();
      if (this.selectedSort !== "") queryParams.set("ordenacao", this.selectedSort);

      if (this.dataFilter.region !== "") queryParams.set("regiao_atuacao", this.dataFilter.region);
      if (this.dataFilter.state !== "") queryParams.set("regiao_estado", this.dataFilter.state);
      if (this.dataFilter.city !== "") queryParams.set("regiao_cidade", this.dataFilter.city);
      if (this.dataFilter.neighborhood !== "") queryParams.set("regiao_bairro", this.dataFilter.neighborhood);
      if (this.dataFilter.unitsSold.length > 0) queryParams.set("unidades_vendidas", this.dataFilter.unitsSold.join(","));
      if (this.dataFilter.activitySegmentation.length > 0) queryParams.set("empreendimento_segmento_atuacao", this.dataFilter.activitySegmentation.join(","));
      if (this.dataFilter.sizeEnterprise.length > 0) queryParams.set("porte_obra_tamanho", this.dataFilter.sizeEnterprise.join(","));
      if (this.dataFilter.generalProgress.length > 0) queryParams.set("obra_avanco_geral", this.dataFilter.generalProgress.join(","));
      if (this.dataFilter.numbersUnits.length > 0) queryParams.set("porte_obra_num_unidades", this.dataFilter.numbersUnits.join(","));
      if (this.dataFilter.typeEnterprise.length > 0) queryParams.set("empreendimento_tipo", this.dataFilter.typeEnterprise.join(","));
      if (this.dataFilter.phaseWork.length > 0) queryParams.set("obra_fase", this.dataFilter.phaseWork.join(","));

      return queryParams;
    },
    getSearchForEnterprises() {

      this.onLoading = true;
      this.isNoContent = false; ""
      this.coordinates = [];
      this.requestdata = [];

      const urlWithFilters = `/api/vitrine/empreendimentos?` + this.setFilter().toString();
      const master = this.$session.get("master");

      axios.get(urlWithFilters, {
        headers: { id_cliente: master.id_cliente },
      })
        .then((response) => {
          const responseData = response.data.empreendimentos;

          if (responseData.length === 0) {
            this.onLoading = false;
            this.isNoContent = true;
          } else {
            this.requestdata = responseData.map((item) => {
              return { ...item.empreendimento, _id: item._id };
            });

            responseData.forEach((item) => {
              this.coordinates.push({
                _id: item._id,
                latitude: item.empreendimento.coordenadas.latitude,
                longitude: item.empreendimento.coordenadas.longitude,
              });
            });

            this.onLoading = false;
            this.isNoContent = false;
          }
        })
        .catch((error) => {
          console.error("Erro ao fazer requisição de empreendimentos:", error);
          this.onLoading = false;
          this.isNoContent = false;
        });
    },

    async saveVenture(idVenture) {

      if (this.saving) return;

      this.saving = true;

      const selectIndex = this.requestdata.findIndex(
        (Ifill) => Ifill._id == idVenture.id
      );

      if (!idVenture.status) {
        try {

          await axios
            .post("/api/vitrine/fundo_investimento/painel_empreendimentos/salvos",
              { _id_empreendimento: idVenture.id },
              {
                headers: {
                  id_cliente: this.$session.get("master").id_cliente,
                },
              })
            .then((response) => {
              this.saving = false;
              this.requestdata[selectIndex].salvo = true;
            });
        } catch (error) {
          this.saving = false;
          console.log(
            "Erro ao adicionar salvo:",
            error
          );
        }
      }

      if (idVenture.status) {
        try {

          await axios
            .delete(`/api/vitrine/fundo_investimento/painel_empreendimentos/salvos/${idVenture.id}`,
              {
                headers: {
                  id_cliente: this.$session.get("master").id_cliente,
                },
              })
            .then((response) => {
              this.saving = false;
              this.requestdata[selectIndex].salvo = false;
            });
        } catch (error) {
          this.saving = false;
          console.log(
            "Erro ao remover salvo:",
            error
          );
        }
      }

    },
    handleSelectedSort() {
      switch (this.selectedSort) {
        case "0":
          this.requestdata = this.mockupdata["painel_empreendimentos"].aceitos
            .slice()
            .sort((a, b) => a.nome.localeCompare(b.nome));
          break;
        case "1":
          this.requestdata = this.mockupdata["painel_empreendimentos"].aceitos
            .slice()
            .sort((a, b) => new Date(b.data) - new Date(a.data));
          break;
        case "2":
          this.requestdata = this.mockupdata["painel_empreendimentos"].aceitos
            .slice()
            .sort((a, b) => new Date(a.data) - new Date(b.data));
          break;
        default:
          break;
      }
    },
    handleFilter(valueFilter) {
      this.dataFilter = valueFilter;
      this.getSearchForEnterprises();
    },
  },
};
</script>


<style lang="scss" scoped>
.buttons-filter-ordering {
  width: 339px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  padding: 0;
}

.selectSort {
  width: 208px;
  height: 33px;
  display: flex;
  border-radius: 6px;
  border: solid 1px #adb5bd;
  background-color: #fff;
  font-size: 16px;
  text-align: left;
  color: #868e96;
}

.selectSort option {
  font-size: 16px;
  text-align: left;
  color: #868e96;
}

.selectSort:focus-within::after {
  border-color: #adb5bd !important;
}

.surface-full {
  width: 100% !important;
  height: calc(100vh - 140px) !important;

  padding: 8px 16px;
  
  flex-direction: row !important;
  justify-content: flex-start !important;
  flex-wrap: wrap !important;
  align-content: flex-start !important;

  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.content-search {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 13px;

  .map {
    width: 100%;
  }

  .surface{
    width: 25%;
    height: calc(100vh - 140px);

    padding-right: 24px;
    
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 13px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 8px 16px 0;

    @media screen and (max-width: 1800px) {
      width: 30%;
    }

    @media screen and (max-width: 1565px) {
      width: 35%;
    }

    @media screen and (max-width: 1460px) {
      width: 40%;
    }

    @media screen and (max-width: 1335px) {
      width: 50%;
    }

    @media screen and (max-width: 1150px) {
      width: 60%;
    }
  }

  @media screen and (max-width: 1023px) {
    flex-direction: column;
    
    .surface{
      width: calc(100vw - 80px);
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;
      height: 400px;
      overflow-y: hidden;
      overflow-x: auto;
      padding: 0 3px;
    }

    .map{
      height: calc(100vh - 550px);
    }
  }
}

.header-slot-template {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin-bottom: 15px;
}

.control-suface {
  display: flex;
  align-items: center;
  transition: all 0.4s ease-in-out;

  button {
    background-color: $white;
    color: $orange;
    border: 1px solid $neutral-50;
    padding: 9px;
    transition: all 0.4s ease-in-out;

    i {
      fill: $orange;
      transition: all 0.4s ease-in-out;
    }
  }

  :focus {
    outline: none;
    box-shadow: none;
  }

  .disabled {
    opacity: unset;
    border-color: $orange;
    transition: all 0.4s ease-in-out;
  }
}

.icon {
  display: inline-block;
  width: 13px;
  height: 13px;
}

/* Para navegadores Webkit (Chrome, Safari) */
.surface::-webkit-scrollbar {
  width: 6px;
  /* Largura da barra de rolagem */
}

.surface::-webkit-scrollbar-thumb {
  background-color: $neutral-50;
  /* Cor da barra de rolagem */
  border-radius: 3px;
  /* Borda arredondada da barra de rolagem */
}

.surface::-webkit-scrollbar-track {
  background-color: #f2f2f2;
  /* Cor do fundo da barra de rolagem */
}

/* Para navegadores Firefox */
.surface {
  scrollbar-width: thin;
  /* Largura da barra de rolagem */
}

.surface::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Cor da barra de rolagem */
  border-radius: 4px;
  /* Borda arredondada da barra de rolagem */
}

.surface::-webkit-scrollbar-track {
  background-color: #f2f2f2;
  /* Cor do fundo da barra de rolagem */
}

.on-loading {
  width: 100%;
  height: 100%;
  z-index: 9999;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  /deep/.spinner-grow-sm {
    width: 4rem;
    height: 4rem;
  }
}

.no-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  height: 100%;

  button {
    width: 245px;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 24px;
    border-radius: 6px;
    background-color: #ed7554;
    border: none;
    color: #fff;
    text-decoration: none;
  }
}

.box-no-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 186px;
  height: 186px;
  border-radius: 100px;
  background-color: #fff;
  margin-bottom: 24px;
}

.icon-no-content {
  width: 72px;
  height: 96px;
  flex-grow: 0;
  z-index: 9999;
}

.title-no-content {
  height: fit-content;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #343a40;
  margin-bottom: 8px;
}

.description-no-content {
  width: 345px;
  height: fit-content;
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: center;
  color: #343a40;
}
</style>

<template>
  <div class="dropdown">
    <b-button @click="toggleDropdown" class="button-filter" variant="primary">
      <div>
        <img class="icon-filter" src="../assets/icon_filter.svg" alt="filtros" />
      </div>
      <div>Filtros</div>
      <div class="icon-filter-bagde" v-show="iconClose" @click="handleIconClose">
        <b-badge variant="danger" style="background-color: #ed7554">X</b-badge>
      </div>
    </b-button>

    <div v-if="show" class="dropdown-content">
      <div class="header-filter">
        <div>Filtros</div>
        <div @click="toggleDropdown">
          <img src="../assets/icon_close.svg" alt="fechar" />
        </div>
      </div>
      <div class="content-filter">

        <div class="business-profiles">
          <span class="content-title"> REGIÃO DE ATUAÇÃO </span><br />
          <!-- <span class="subtitle-orange"> Região de atuação </span> -->

          <div class="container-selected-filter">
            <div>
              <label class="selected-label">Região</label>
              <div style="width: 100%">
                <select v-model="filters.region" @change="() => handleStates()" class="selected-filter">
                  <option value="" selected>Todas</option>
                  <option v-for="option in reqRegion" v-bind:value="option.value" :key="option.value">
                    {{ option.label }}
                  </option>
                </select>
              </div>
            </div>
            <div>
              <label class="selected-label">Estado</label>
              <div style="width: 100%">
                <select v-model="filters.state" @change="() => handleCities()" class="selected-filter">
                  <option value="" selected>Todos</option>
                  <option v-for="option in reqStates" v-bind:value="option.value" :key="option.value">
                    {{ option.label }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div>
            <label class="selected-label">Cidade</label>
            <div style="width: 100%">
              <select v-model="filters.city" :disabled="loadingCities" @change="() => handleNeighborhood()"
                class="selected-filter">
                <option v-if="loadingCities" value="" selected>Carregando...</option>
                <option v-else-if="!loadingCities" value="" selected>Todas</option>
                <option v-for="option in reqCities" v-bind:value="option" :key="option">
                  {{ option }}
                </option>
              </select>
            </div>
          </div>
          <div>
            <label class="selected-label">Bairro</label>
            <div style="width: 100%">
              <select :disabled="!filters.city.length || loadingNeighborhood" v-model="filters.neighborhood"
                class="selected-filter">
                <option v-if="loadingNeighborhood" value="" selected>Carregando...</option>
                <option v-else-if="!loadingNeighborhood" value="" selected>Todos</option>
                <option v-for="option in reqNeighborhood" v-bind:value="option" :key="option">
                  {{ option }}
                </option>
              </select>
            </div>
          </div>

          <div class="divider">
            <hr />
          </div>

          <span class="content-title"> EMPREENDIMENTOS </span><br />

          <div class="segmentation-type">

            <div style="width: 100%">
              <div style="display:flex">
                <div>
                  <label class="selected-label">Tipo</label>
                </div>
                <div class="button-collapse-content">
                  <b-button v-b-toggle.collapse-type variant="light" class="d-flex btn-accordion border-0"
                    @click='visibleCollapses.type = !visibleCollapses.type'>
                    <chevron-right-icon v-if="!visibleCollapses.type" size="1.5x"
                      class="chevron-icon"></chevron-right-icon>
                    <chevron-down-icon v-if="visibleCollapses.type" size="1.5x"
                      class="chevron-icon"></chevron-down-icon>
                  </b-button>
                </div>
              </div>
              <div>
                <b-collapse id="collapse-type">
                  <b-card style="border: 0">
                    <ul>
                      <li class="li-align" v-for="(type, index) in typeEnterprise" :key="index">
                        <input type="checkbox" v-model="typeEnterprise[index].active"
                          @change="getSelectedTypeEnterprise" />
                        {{ type.label }}
                      </li>
                    </ul>
                  </b-card>
                </b-collapse>
              </div>
            </div>

            <div style="width: 100%">
              <div style="display:flex">
                <div>
                  <label class="selected-label">Segmento</label>
                </div>
                <div class="button-collapse-content">
                  <b-button v-b-toggle.collapse-segment variant="light" class="d-flex btn-accordion border-0"
                    @click='visibleCollapses.segmentation = !visibleCollapses.segmentation'>
                    <chevron-right-icon v-if="!visibleCollapses.segmentation" size="1.5x"
                      class="chevron-icon"></chevron-right-icon>
                    <chevron-down-icon v-if="visibleCollapses.segmentation" size="1.5x"
                      class="chevron-icon"></chevron-down-icon>
                  </b-button>
                </div>
              </div>
              <div>
                <b-collapse id="collapse-segment">
                  <b-card style="border: 0">
                    <ul>
                      <li class="li-align" v-for="(segmentation, index) in activitySegmentation" :key="index">
                        <input type="checkbox" v-model="activitySegmentation[index].active"
                          @change="getSelectedActivitySegmentation" />
                        {{ segmentation.label }}
                      </li>
                    </ul>
                  </b-card>
                </b-collapse>
              </div>
            </div>
          </div>

          <div class="divider">
            <hr />
          </div>

          <span class="content-title"> PORTE INCORPORAÇÃO </span><br />

          <div style="width: 100%">
            <div style="display:flex">
              <div>
                <label class="selected-label">Tamanho</label>
              </div>
              <div class="button-collapse-content">
                <b-button v-b-toggle.collapse-size variant="light" class="d-flex btn-accordion border-0"
                  @click='visibleCollapses.size = !visibleCollapses.size'>
                  <chevron-right-icon v-if="!visibleCollapses.size" size="1.5x"
                    class="chevron-icon"></chevron-right-icon>
                  <chevron-down-icon v-if="visibleCollapses.size" size="1.5x" class="chevron-icon"></chevron-down-icon>
                </b-button>
              </div>
            </div>
            <div>
              <b-collapse id="collapse-size">
                <b-card style="border: 0">
                  <ul>
                    <li class="li-align" v-for="(enterprise, index) in sizeEnterprise" :key="index">
                      <input type="checkbox" v-model="sizeEnterprise[index].active"
                        @change="getSelectedSizeEnterprise" />
                      {{ enterprise.label }}
                    </li>
                  </ul>
                </b-card>
              </b-collapse>
            </div>
          </div>

          <div style="width: 100%">
            <div style="display:flex">
              <div>
                <label class="selected-label">Nº total de unidades</label>
              </div>
              <div class="button-collapse-content">
                <b-button v-b-toggle.collapse-number-units variant="light" class="d-flex btn-accordion border-0"
                  @click='visibleCollapses.units = !visibleCollapses.units'>
                  <chevron-right-icon v-if="!visibleCollapses.units" size="1.5x"
                    class="chevron-icon"></chevron-right-icon>
                  <chevron-down-icon v-if="visibleCollapses.units" size="1.5x" class="chevron-icon"></chevron-down-icon>
                </b-button>
              </div>
            </div>
            <div>
              <b-collapse id="collapse-number-units">
                <b-card style="border: 0">
                  <ul>
                    <li class="li-align" v-for="(units, index) in numbersUnits" :key="index">
                      <input type="checkbox" v-model="numbersUnits[index].active" @change="getSelectedNumberUnity" />
                      {{ units.label }}
                    </li>
                  </ul>
                </b-card>
              </b-collapse>
            </div>
          </div>

          <div style="width: 100%">
            <div style="display:flex">
              <div>
                <label class="selected-label">Nº de unidades vendidas</label>
              </div>
              <div class="button-collapse-content">
                <b-button v-b-toggle.collapse-unit-solds variant="light" class="d-flex btn-accordion border-0"
                  @click='visibleCollapses.sold = !visibleCollapses.sold'>
                  <chevron-right-icon v-if="!visibleCollapses.sold" size="1.5x"
                    class="chevron-icon"></chevron-right-icon>
                  <chevron-down-icon v-if="visibleCollapses.sold" size="1.5x" class="chevron-icon"></chevron-down-icon>
                </b-button>
              </div>
            </div>
            <div>
              <b-collapse id="collapse-unit-solds">
                <b-card style="border: 0">
                  <ul>
                    <li class="li-align" v-for="(unitSold, index) in unitsSold" :key="index">
                      <input type="checkbox" v-model="unitsSold[index].active" @change="getSelectedUnitsSold" />
                      {{ unitSold.label }}
                    </li>
                  </ul>
                </b-card>
              </b-collapse>
            </div>
          </div>

          <div class="divider">
            <hr />
          </div>

          <span class="content-title"> OBRA </span><br />

          <div style="width: 100%">
            <div style="display:flex">
              <div>
                <label class="selected-label">Fase da obra</label>
              </div>
              <div class="button-collapse-content">
                <b-button v-b-toggle.collapse-phase-work variant="light" class="d-flex btn-accordion border-0"
                  @click='visibleCollapses.phase = !visibleCollapses.phase'>
                  <chevron-right-icon v-if="!visibleCollapses.phase" size="1.5x"
                    class="chevron-icon"></chevron-right-icon>
                  <chevron-down-icon v-if="visibleCollapses.phase" size="1.5x" class="chevron-icon"></chevron-down-icon>
                </b-button>
              </div>
            </div>
            <div>
              <b-collapse id="collapse-phase-work">
                <b-card style="border: 0">
                  <ul>
                    <li class="li-align" v-for="(phase, index) in phaseWork" :key="index">
                      <input type="checkbox" v-model="phaseWork[index].active" @change="getSelectedPhase" />
                      {{ phase.label }}
                    </li>
                  </ul>
                </b-card>
              </b-collapse>
            </div>
          </div>

          <div style="width: 100%">
            <div style="display:flex">
              <div>
                <label class="selected-label">Avanço geral da obra</label>
              </div>
              <div class="button-collapse-content">
                <b-button v-b-toggle.collapse-general-progress variant="light" class="d-flex btn-accordion border-0"
                  @click='visibleCollapses.progress = !visibleCollapses.progress'>
                  <chevron-right-icon v-if="!visibleCollapses.progress" size="1.5x"
                    class="chevron-icon"></chevron-right-icon>
                  <chevron-down-icon v-if="visibleCollapses.progress" size="1.5x"
                    class="chevron-icon"></chevron-down-icon>
                </b-button>
              </div>
            </div>
            <div>
              <b-collapse id="collapse-general-progress">
                <b-card style="border: 0">
                  <ul>
                    <li class="li-align" v-for="(progress, index) in generalProgress" :key="index">
                      <input type="checkbox" v-model="generalProgress[index].active" @change="getSelectedProgress" />
                      {{ progress.label }}
                    </li>
                  </ul>
                </b-card>
              </b-collapse>
            </div>
          </div>

        </div>

      </div>
      <div class="footer-filter">
        <b-button class="button-reset" @click="handleReset()">
          Limpar filtro
        </b-button>
        <b-button class="button-filter-footer" @click="SendFilterData()">
          Filtrar
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>

import dataMockup from "../dataMockup/dataMockup.json";
import { ChevronRightIcon, ChevronDownIcon } from "vue-feather-icons";
import axios from "axios";
import _ from "lodash";

export default {
  name: "PainelDrop",
  components: {
    ChevronRightIcon,
    ChevronDownIcon,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visao_usuario: null,
      filters: {
        region: "",
        state: "",
        city: "",
        neighborhood: "",
        typeEnterprise: [],
        activitySegmentation: [],
        sizeEnterprise: [],
        numbersUnits: [],
        unitsSold: [],
        phaseWork: [],
        generalProgress: []
      },
      iconClose: false,
      reqStates: [],
      reqCities: [],
      reqNeighborhood: [],
      reqRegion: dataMockup["region"].data,

      typeEnterprise: dataMockup["typeEnterprise"].data,
      activitySegmentation: dataMockup["activitySegmentation"].data,

      sizeEnterprise: dataMockup["sizeEnterprise"].data,
      numbersUnits: dataMockup["numbersUnits"].data,
      unitsSold: dataMockup["unitsSold"].data,

      phaseWork: dataMockup["phaseWork"].data,
      generalProgress: dataMockup["generalProgress"].data,

      visibleCollapses: {
        type: false,
        segmentation: false,
        size: false,
        units: false,
        sold: false,
        phase: false,
        progress: false
      },

      loadingCities: false,
      loadingNeighborhood: false
    };
  },
  mounted() {
    const client = this.$router.app.$session.get("client");
    this.visao_usuario = client.visao_usuario;

    this.handleReset();
  },
  methods: {
    handleStates() {
      this.reqCities = [];
      this.reqNeighborhood = [];
      this.filters.state = "";
      this.filters.city = "";
      this.filters.neighborhood = "";

      if (!this.filters?.region?.length) {
        this.reqStates = dataMockup["states"].data;
      } else {
        this.reqStates = dataMockup["states"].data.filter(
          (f) => f.region == this.filters.region
        );
      }

      this.handleCities();
    },
    async handleCities() {
      this.filters.city = "";
      this.filters.neighborhood = "";

      this.reqCities = [];
      this.loadingCities = true;

      const filter = 
        `regiao=${this.filters.region.toLocaleLowerCase()}` +
        `&estado=${this.filters.state.toLocaleLowerCase()}`;

      try {
        this.reqCities = await axios.get(`/api/vitrine/cidades?${filter}`).then((response) => {
          return response.data.cidades;
        });
      } catch (error) {
        console.error("Erro ao fazer requisição de aceitos:", error);
      } finally {
        this.loadingCities = false;
      }
    },
    async handleNeighborhood() {
      this.filters.neighborhood = "";

      this.reqNeighborhood = [];
      this.loadingNeighborhood = true;

      const filter =
        `regiao=${this.filters.region.toLocaleLowerCase()}` +
        `&estado=${this.filters.state.toLocaleLowerCase()}` +
        `&cidade=${this.filters.city.toLocaleLowerCase()}`;

      try {
        this.reqNeighborhood = await axios.get(`/api/vitrine/bairros?${filter}`).then((response) => {
          return response.data.bairros;
        });
      } catch (error) {
        console.error("Erro ao fazer requisição de aceitos:", error);
      } finally {
        this.loadingNeighborhood = false;
      }
    },
    getSelectedTypeEnterprise() {
      this.filters.typeEnterprise = [];
      this.typeEnterprise.map((m) => {
        if (m.active) {
          this.filters.typeEnterprise.push(m.value);
        }
      });
    },
    getSelectedActivitySegmentation() {
      this.filters.activitySegmentation = [];
      this.activitySegmentation.map((m) => {
        if (m.active) {
          this.filters.activitySegmentation.push(m.value);
        }
      });
    },
    getSelectedSizeEnterprise() {
      this.filters.sizeEnterprise = [];
      this.sizeEnterprise.map((m) => {
        if (m.active) {
          this.filters.sizeEnterprise.push(m.value);
        }
      });
    },
    getSelectedNumberUnity() {
      this.filters.numbersUnits = [];
      this.numbersUnits.map((m) => {
        if (m.active) {
          this.filters.numbersUnits.push(m.value);
        }
      });
    },
    getSelectedUnitsSold() {
      this.filters.unitsSold = [];
      this.unitsSold.map((m) => {
        if (m.active) {
          this.filters.unitsSold.push(m.value);
        }
      });
    },
    getSelectedPhase() {
      this.filters.phaseWork = [];
      this.phaseWork.map((m) => {
        if (m.active) {
          this.filters.phaseWork.push(m.value);
        }
      });
    },
    getSelectedProgress() {
      this.filters.generalProgress = [];
      this.generalProgress.map((m) => {
        if (m.active) {
          this.filters.generalProgress.push(m.value);
        }
      });
    },
    handleResetFilterData() {
      this.$emit("emitSendFilter", false);
    },
    handleReset() {
      this.filters = {
        region: "",
        state: "",
        city: "",
        neighborhood: "",
        typeEnterprise: [],
        activitySegmentation: [],
        sizeEnterprise: [],
        numbersUnits: [],
        unitsSold: [],
        phaseWork: [],
        generalProgress: [],
      };
      this.reqStates = [];
      this.reqCities = [];
      this.reqNeighborhood = [];
      this.handleStates();

      this.typeEnterprise.map((m, i) => {
        this.typeEnterprise[i].active = false;
      });
      this.activitySegmentation.map((m, i) => {
        this.activitySegmentation[i].active = false;
      });

      this.sizeEnterprise.map((m, i) => {
        this.sizeEnterprise[i].active = false;
      });
      this.numbersUnits.map((m, i) => {
        this.numbersUnits[i].active = false;
      });
      this.unitsSold.map((m, i) => {
        this.unitsSold[i].active = false;
      });

      this.phaseWork.map((m, i) => {
        this.phaseWork[i].active = false;
      });
      this.generalProgress.map((m, i) => {
        this.generalProgress[i].active = false;
      });
    },
    toggleDropdown() {
      this.$emit("emitShowDrop", !this.show);
    },
    SendFilterData() {

      const compare = {
        region: "",
        state: "",
        city: "",
        neighborhood: "",
        typeEnterprise: [],
        activitySegmentation: [],
        sizeEnterprise: [],
        numbersUnits: [],
        unitsSold: [],
        phaseWork: [],
        generalProgress: [],
      };
      // if (!_.isEqual(compare, this.filters)) {
      //   this.iconClose = true;
      //   this.$emit("emitSendFilter", this.filters);
      // }
      this.iconClose = true;
      this.$emit("emitSendFilter", this.filters);
      this.toggleDropdown();     
    },
    handleIconClose() {
      this.handleReset();
      this.$emit("emitSendFilter", this.filters);
      this.iconClose = false;
      this.toggleDropdown();
    }
  },
};
</script>
<style lang="scss" scoped>
.button-filter {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 33px;
  padding: 0px 16px;
  width: 115px;
  gap: 8px;
  border-radius: 6px;
  border: solid 1px $orange;
  background-color: #fff;
  color: $orange;
  font-size: 16px;

  img {
    width: 12px;
    height: 12px;
    color: $orange;
  }
}

.button-filter:active {
  background-color: #fff !important;
  color: $orange !important;
}

.dropdown-content {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 542px;
  height: 710px;
  top: 33px;
  left: -425px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  border-radius: 8px;
  background-color: #fff;



  @media screen and (max-width: 1023px) {
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0%);
  }
}

.header-filter,
.content-filter,
.footer-filter {
  width: 542px;
  display: flex;
}

.header-filter {
  height: 50px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px 40px;
  border-radius: 8px 8px 0px 0px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #212529;
}

.content-filter {
  flex-direction: column;
  height: 600px !important;
  overflow-y: scroll;
}

.footer-filter {
  height: 60px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  padding: 24px 40px;
  border-radius: 0px 0px 8px 8px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
}

.content-title {
  margin: 5px 0px;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.0;
  letter-spacing: normal;
  text-align: left;
  color: #722381;
}

.container-selected-filter {
  display: flex;
  justify-content: space-between;
  gap: 8px;

  div {
    width: 50%;
  }
}


.selected-label {
  align-self: stretch;
  flex-grow: 0;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #000 !important;
}

.selected-filter {
  height: 29px;
  width: 100%;
  border-radius: 6px;
  border: solid 1px #adb5bd;
  background-color: #fff;
}

.divider {
  // padding: 0px 10px;

  hr {
    color: #ced4da;
  }
}

.subtitle-orange {
  padding: 24px 0px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #ed7554;
}

.business-profiles {
  margin-top: 10px;
  padding: 0px 20px;
}

.empreendimentos {
  display: flex;
  width: 100%;
  margin-top: 10px;

  div {
    width: 50%;
  }

  li {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: #212529;
    list-style-type: none;
    margin-left: -40px;
  }
}

.card-body {
  padding: 0;
}

ul {
  padding: 0;
}

.li-align {
  display: flex;
  align-items: center;
  widows: 50%;

  input {
    margin-right: 4px;
  }
}

.selected-label-type {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #212529;
}

.button-collapse-content {
  margin-left: auto;
  margin-right: 0;
}

.selected-label-text {
  width: 100% !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: #212529;
}

.button-filter-footer {
  height: 40px;
  width: 223px;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 9px 24px;
  border-radius: 6px;
  background-color: $orange;
  color: #fff;
}

.button-reset {
  height: 40px;
  width: 223px;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 9px 24px;
  border-radius: 6px;
  border: solid 1px #adb5bd;
  background-color: #fff;
  color: #000 !important;
}

.phase-work {
  margin: 8px 0px;

  li {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: #212529;
    list-style-type: none;
    margin-left: -40px;
  }
}

.icon-filter-bagde {
  cursor: pointer;
}

@media only screen and (min-height: 600px) and (max-height: 900px) {
  .dropdown-content {
    height: 442px;
    left: -428px;
  }

  .content-filter {
    overflow-y: scroll;
    width: 100%;
  }
}
</style>

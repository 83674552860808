<template>
  <div class="app-content">
    <router-view class="router-content"></router-view>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.app-content {
  height: 100vh;
  position: relative;
  width: 100%;
  padding: 38px;
  background-color: $background;

  @media screen and (max-width: 1023px) {
    padding: 14px;
  }
}
.router-content {
  height: 100%;
  width: 100%;
  max-width: 1850px;
}
</style>
class Formatters {
  constructor() {}

  formatCnpj(cnpj) {
    cnpj = cnpj.replace(/\D/g, "");

    if (cnpj.length > 14) {
      cnpj = cnpj.slice(0, 14);
    } else if (cnpj.length > 2 && cnpj.length <= 5) {
      cnpj = cnpj.replace(/^(\d{2})(\d)/, "$1.$2");
    } else if (cnpj.length > 5 && cnpj.length <= 8) {
      cnpj = cnpj.replace(/^(\d{2})(\d{3})(\d)/, "$1.$2.$3");
    } else if (cnpj.length > 8 && cnpj.length <= 12) {
      cnpj = cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d)/, "$1.$2.$3/$4");
    } else if (cnpj.length > 12) {
      cnpj = cnpj.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        "$1.$2.$3/$4-$5"
      );
    }

    return cnpj;
  }

  formatCpf(cpf) {
    cpf = cpf.replace(/\D/g, "");

    if (cpf.length > 11) {
      cpf = cpf.slice(0, 11);
    } else if (cpf.length > 3 && cpf.length <= 6) {
      cpf = cpf.replace(/^(\d{3})(\d)/, "$1.$2");
    } else if (cpf.length > 6 && cpf.length <= 9) {
      cpf = cpf.replace(/^(\d{3})(\d{3})(\d)/, "$1.$2.$3");
    } else if (cpf.length > 9) {
      cpf = cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    }

    return cpf;
  }

  formatMoney(value) {
    value = value.replace(/\D/g, "");
    value = (value / 100).toFixed(2) + "";
    value = value.replace(".", ",");
    value = value.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    value = "R$ " + value;

    return value;
  }

  formatPhone(phone) {
    phone = phone.replace(/\D/g, "");

    if (phone.length > 11) {
      phone = phone.slice(0, 11);
    } else if (phone.length > 2 && phone.length <= 6) {
      phone = phone.replace(/^(\d{2})(\d)/, "($1) $2");
    } else if (phone.length > 6 && phone.length <= 10) {
      phone = phone.replace(/^(\d{2})(\d{4})(\d)/, "($1) $2-$3");
    } else if (phone.length > 10) {
      phone = phone.replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
    }

    return phone;
  }

  brazilianRealToFloat(value) {
    value = value.replaceAll("R$ ", "");
    value = value.replaceAll(".", "");
    value = value.replaceAll(",", ".");
    return parseFloat(value);
  }

  phoneToString(phone) {
    phone = phone.replace(/\D/g, "");
    return phone;
  }

  formaterPercentage(value) {
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d)(?=(\d{2})+(?!\d))/g, "$1,");
    value = value + "%";
    return value;
  }
}

export default new Formatters();

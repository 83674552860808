<template>
  <div :class="{ 'card-main': !formap, 'card-main-map': formap }" @click="onClickCard">
    <div class="image-container">
      <img width="100%" height="145px" :src="data.empreendimento.imagens.length > 0
          ? data.empreendimento.imagens[0].link
          : 'https://img.freepik.com/fotos-gratis/steel-business-predio-urbano-observacao_1127-2397.jpg?t=st=1710792644~exp=1710796244~hmac=7bf616b9f1b5dcb16adda6b819ed8b658dd83c9ab7b51886ae7df82decb9a775&w=1380'
        " alt="img" />
      <div v-if="data.empreendimento.estatisticas.interesses.length > 0" class="availability-badge" :id="data._id"
        :tabindex="data._id" v-b-tooltip.html :title="`<strong>${data.empreendimento.estatisticas.interesses.length}</strong> Fundos
        de investimento se interessaram <br />
        por esse empreendimento.`">
        {{ data.empreendimento.estatisticas.interesses.length }}
      </div>

    </div>
    <div class="type">
      <h6>{{ data.empreendimento.nome_empreendimento }}</h6>
      <b-dropdown variant="transparent" right no-caret>
        <template #button-content>
          <img :src="require('@/assets/Three-dots-vertical.svg')" alt="Ações" class="-dots-vertical" />
        </template>
        <b-dropdown-item @click.stop.prevent="editItem(data)">
          <div class="Menu-1">Editar</div>
        </b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item @click.stop.prevent="openConfirmDeleteModal(data)">
          <div class="Menu-2">Excluir</div>
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <br />
    <div class="address">
      <i class="icon icon-geo"></i>
      <span>{{
        `${data.empreendimento.bairro} - ${data.empreendimento.cidade}, ${data.empreendimento.estado}`
        }}</span>
    </div>
    <div class="item-label">
      <span>Visualizado </span>
      <strong>{{ `${data.empreendimento.estatisticas.visualizados.length} vezes` }}</strong>
    </div>
    <div class="item-label">
      <span>Salvo</span>
      <strong>{{ `${data.empreendimento.estatisticas.salvos.length} vezes` }}</strong>
    </div>
    <b-modal id="modal-confirm-delete" v-model="showConfirmModal" @ok="confirmDelete" ok-title="Sim" ok-variant="danger"
      cancel-title="Não" cancel-variant="outline-secondary" size="md" centered>
      <!-- Conteúdo do modal -->
      <template #modal-title>
        <strong>Excluir empreendimento</strong>
      </template>
      <template modal-body>
        <strong>Você tem certeza de que deseja excluir esse empreendimento?</strong>
      </template>
      <template #modal-footer>
        <b-button size="md" variant="secondary" @click="showConfirmModal = false">
          Não
        </b-button>
        <b-button size="md" variant="danger" @click="confirmDelete">
          Sim
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="modalShow" title="Editar Empreendimento" size="xl" centered cancel-title="Cancelar"
      ok-title="Concluir" @ok="saveEnterprise" lazyf>
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group label="Nome do empreendimento" label-for="nome_empreendimento" label-class="font-weight-bold">
              <b-form-input id="nome_empreendimento" v-model="nome_empreendimento" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Tipo de empreendimento" label-for="tipo_empreendimento" label-class="font-weight-bold">
              <select id="tipo_empreendimento" v-model="tipo_empreendimento" class="form-control">
                <option v-for="option in typeEmpOptions" :key="option.value" :value="option.value">
                  {{ option.label }}
                </option>
              </select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="2">
            <b-form-group label="Segmento" label-for="segmento_atuacao" label-class="font-weight-bold">
              <select id="segmento_atuacao" v-model="segmento_atuacao" class="form-control">
                <option v-for="option in typeSegments" :key="option.value" :value="option.value">
                  {{ option.label }}
                </option>
              </select>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label="Custo Estimado (R$)" label-for="custo_estimado_reais" label-class="font-weight-bold">
              <b-form-input id="custo_estimado_reais" v-model="custo_estimado_reais" type="text" class="form-control" @input="updateAverageCost" v-numeric-only />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label="VGV (R$)" label-for="vgv_reais" label-class="font-weight-bold">
              <b-form-input id="vgv_reais" v-model="vgv_reais" type="text" class="form-control" @input="updateVgvValue" v-numeric-only />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Fase de Obra" label-for="fase_obra" label-class="font-weight-bold">
              <select id="fase_obra" v-model="fase_obra" class="form-control">
                <option v-for="option in constructionPhaseOptions" :key="option.value" :value="option.value">
                  {{ option.label }}
                </option>
              </select>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group label="Avanço Geral da Obra (%)" label-for="avanco_geral_obra" label-class="font-weight-bold">
              <b-form-input id="avanco_geral_obra" v-model="avanco_geral_obra" type="text" @input="updateGeneralProgress" v-numeric-only  />
            </b-form-group>
          </b-col>

          <!-- <b-col md="2">
            <b-form-group
              label="Avanço Geral da Obra"
              label-for="avanco_geral_obra"
              label-class="font-weight-bold"
            >
              <select
                id="avanco_geral_obra"
                v-model="avanco_geral_obra"
                class="form-control"
              >
                <option
                  v-for="option in generalProgressOfWorkOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.label }}
                </option>
              </select>
            </b-form-group>
          </b-col> -->

        </b-row>
        <b-row>
          <b-col md="2">
            <b-form-group label="N° de Unidades" label-for="numero_unidades" label-class="font-weight-bold">
              <b-form-input id="numero_unidades" v-model="numero_unidades" type="text" class="form-control"
                v-numeric-only />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label="Área das Unidades (m²)" label-for="area_unidades_m2" label-class="font-weight-bold">
              <select id="area_unidades_m2" v-model="area_unidades_m2" class="form-control">
                <option v-for="option in areaUnitsOptions" :key="option.value" :value="option.value">
                  {{ option.label }}
                </option>
              </select>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label="Ticket Médio (R$)" label-for="ticket_medio" label-class="font-weight-bold">
              <b-form-input id="ticket_medio" v-model="ticket_medio" type="text" @input="updateAverageTicket" v-numeric-only />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Unidades Vendidas" label-for="unidades_vendidas" label-class="font-weight-bold">
              <b-form-input id="unidades_vendidas" v-model="unidades_vendidas" type="text" v-numeric-only />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Estoque" label-for="estoque" label-class="font-weight-bold">
              <b-form-input id="estoque" v-model="estoque" type="text" v-numeric-only />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group label="Endereço" label-for="endereco" label-class="font-weight-bold">
              <b-form-input type="text" id="autocomplete" name="autocomplete" ref="inputSearch"
                placeholder="Informe o endereço" v-model="endereco" />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label="Estado" label-for="estado" label-class="font-weight-bold">
              <b-form-input type="text" id="estado" v-model="estado" class="form-control" :key="isKey"
                :disabled="true" />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Cidade" label-for="cidade" label-class="font-weight-bold">
              <b-form-input type="text" id="cidade" v-model="cidade" class="form-control" :key="isKey"
                :disabled="true" />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Bairro" label-for="bairro" label-class="font-weight-bold">
              <b-form-input :disabled="true" id="bairro" v-model="bairro" :key="isKey" />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <b-form-group label="Imagens" label-for="imagens" label-class="font-weight-bold">
              <div class="icon-upload">
                <label for="imagens" class="upload-label">
                  <img src="../assets/Icon_Upload.svg" alt="Upload Icon" class="upload-icon" />
                  <span class="text-icon-upload">
                    Arraste as imagens aqui ou clique para selecionar
                  </span>
                </label>
                <b-form-file id="imagens" placeholder=" " drop-placeholder=" " multiple class="file-browse"
                  @change="handleImageUpload" accept="image/*" />
              </div>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Documentos do Empreendimento" label-for="documentos" label-class="font-weight-bold">
              <div class="icon-upload">
                <label for="documentos" class="upload-label">
                  <img src="../assets/Icon_Upload.svg" alt="Upload Icon" class="upload-icon" />
                  <span class="text-icon-upload">
                    Arraste os documentos aqui ou clique para selecionar
                  </span>
                </label>
                <b-form-file id="documentos" placeholder=" " drop-placeholder=" " multiple class="file-browse"
                  @change="handleDocumentUpload" accept=".pdf" />
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="miniatura-container imagens">
          <div v-for="(imagem, index) in imagens" :key="index" class="miniatura imagem">
            <img :src="imagem.link" alt="Miniatura da Imagem" />
            <div class="file-name">
              {{ imagem.titulo }}
            </div>
            <button class="btn-delete" @click.prevent="deleteImage(index)">
              <img src="../assets/icon-trash.svg" alt="Excluir" />
            </button>
          </div>
        </div>

        <div class="miniatura-container documentos">
          <div v-for="(documento, index) in documentos" :key="index" class="miniatura documento">
            <img src="../assets/icon-file.svg" alt="Ícone de Documento" />
            <div class="file-name">
              <!-- {{ extractFileName(documento.titulo) }} -->
            </div>
            <button class="btn-delete" @click.prevent="deleteDocument(index)">
              <img src="../assets/icon-trash.svg" alt="Excluir" />
            </button>
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import APIVitrine from "@/server/APIVitrine";

import Formatters from "@/utils/Formatters.js";

export default {
  props: {
    data: {
      type: [Object, Array],
      required: true,
    },
    formap: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isKey: 0,
      autocomplete: null,
      place: null,
      lat: null,
      lng: null,
      address: "",
      address_name: "",
      // showAvailabilityBadge: false,
      // availabilityNumber: null,
      showTooltip: false,
      showConfirmModal: false, // Adicione esta linha
      currentItem: null, // E esta se você ainda não tem
      modalShow: false,
      typeEmpOptions: [
        { label: "Residencial Vertical", value: "Residencial Vertical" },
        { label: "Residencial Horizontal", value: "Residencial Horizontal" },
      ],
      typeSegments: [
        { value: "special", label: "Especial (1 Dorm. até 40m²)" },
        { value: "economy", label: "Econômico (até R$ 264mil)" },
        { value: "standard", label: "Standard (R$ 264mil - R$ 500mil)" },
        { value: "medium", label: "Médio (R$ 500mil - R$ 1M)" },
        { value: "high", label: "Alto (R$ 1M - R$ 1.5M)" },
        { value: "lux", label: "Luxo (R$ 1.5M - R$ 3M)" },
        { value: "highlux", label: "Super Luxo (R$ 3M+)" },
      ],
      constructionPhaseOptions: [
        { label: "Busca de terreno", value: "busca-de-terreno" },
        { label: "Preparação", value: "preparacao" },
        { label: "Terraplanagem", value: "terraplanagem" },
        { label: "Escavação", value: "escavacao" },
        { label: "Fundação", value: "fundacao" },
        { label: "Estrutura", value: "estrutura" },
        { label: "Alvenaria", value: "alvenaria" },
        { label: "Acabamento", value: "acabamento" },
        { label: "Entrega", value: "entrega" }
      ],
      // generalProgressOfWorkOptions: [
      //   { label: "Opção 1", value: "option1" }
      // ],
      areaUnitsOptions: [
        { label: "0-40m²", value: "0-40m" },
        { label: "40-60m²", value: "40-60m" },
        { label: "60-80m²", value: "60-80m" },
        { label: "80-100m²", value: "80-100m" },
        { label: "100-130m²", value: "100-130m" },
        { label: "130-180m²", value: "130-180m" },
        { label: "250m²+", value: "250m²+" }
      ],
      editedItem: null,
      imagensEmpreendimento: [],
      documentosEmpreendimento: [],

      nome_empreendimento: this.data.empreendimento.nome_empreendimento,
      tipo_empreendimento: this.data.empreendimento.tipo_empreendimento[0],
      segmento_atuacao: this.data.empreendimento.segmento_atuacao[0],
      custo_estimado_reais: Formatters.formatMoney(String(parseFloat(this.data.empreendimento.custo_estimado_reais)).replace(".", "")),
      vgv_reais: Formatters.formatMoney(String(parseFloat(this.data.empreendimento.vgv_reais)).replace(".", "")),
      fase_obra: this.data.empreendimento.fase_obra[0],
      avanco_geral_obra: Formatters.formaterPercentage(String(parseFloat(this.data.empreendimento.avanco_geral_obra)).replace(".", ",")),
      numero_unidades: parseInt(this.data.empreendimento.numero_unidades),
      area_unidades_m2: this.data.empreendimento.area_unidades_m2[0],
      ticket_medio: Formatters.formatMoney(String(parseFloat(this.data.empreendimento.ticket_medio)).replace(".", "")),
      unidades_vendidas: parseInt(this.data.empreendimento.unidades_vendidas),
      estoque: parseInt(this.data.empreendimento.estoque),
      endereco: this.data.empreendimento.endereco,
      estado: this.data.empreendimento.estado,
      cidade: this.data.empreendimento.cidade,
      bairro: this.data.empreendimento.bairro,
      imagens: this.data.empreendimento.imagens,
      documentos: this.data.empreendimento.documentos,
      lat: this.data.empreendimento.coordenadas.latitude,
      lng: this.data.empreendimento.coordenadas.longitude,

      imagesEnterprise: [],
      documentsEnterprise: [],
    };
  },
  directives: {
    'numeric-only': {
      bind(el) {
        el.addEventListener('keydown', (e) => {
          if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
            e.preventDefault();
          }
        });
      }
    }
  },
  mounted() {
  },
  methods: {
    //TODO Transformar metodo do google em utils e transformar tambem em utils metodod de digitar number e metodo de salvar
    //imagens e documentos
    //TODO: transformar o salvar e documentos em um componente
    updateGeneralProgress(value) {
      const valueWithoutString = value.replaceAll(/\D/g, "");
      this.avanco_geral_obra = Formatters.formaterPercentage(valueWithoutString);
    },
    updateAverageCost(value) {
      const valueWithOutString = value.replaceAll(/\D/g, "")
      this.custo_estimado_reais = Formatters.formatMoney(valueWithOutString);
    },
    updateVgvValue(value) {
      const valueWithOutString = value.replaceAll(/\D/g, "")
      this.vgv_reais = Formatters.formatMoney(valueWithOutString);
    },
    updateAverageTicket(value) {
      const valueWithOutString = value.replaceAll(/\D/g, "")
      this.ticket_medio = Formatters.formatMoney(valueWithOutString);
    },
    onClickCard() {
      this.$router.push({
        name: "enterpriseDetail",
        params: {
          id: this.data._id,
          data: this.data,
          status: this.status,
          comesFrom: this.$route.meta.name
        },
      });
    },
    async handleImageUpload(event) {
      const files = event.target.files;
      const newImages = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        if (!file.type.startsWith("image/")) {
          console.warn(
            `${file.name} não é um arquivo de imagem. Será ignorado.`
          );
          continue;
        }

        const response = await APIVitrine.uploadFile(file);

        const formattedResponse = {
          titulo: response.file,
          descricao: response.mimetype,
          link: response.url,
        };

        // Adicionando a nova imagem à lista temporária
        newImages.push(formattedResponse);
      }

      // Adicionando as novas imagens à lista existente usando o spread operator
      this.imagens = [...this.imagens, ...newImages];
    },
    async handleDocumentUpload(event) {
      const newDocuments = [];
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file.type.startsWith("application/pdf")) {
          const response = await APIVitrine.uploadFile(file);
          const formattedResponse = {
            titulo: response.file,
            descricao: response.mimetype,
            link: response.url,
          };

          newDocuments.push(formattedResponse);

          this.documentos = [...this.documentos, ...newDocuments];
        } else {
          console.warn(
            `${file.name} não é um arquivo do tipo PDF. Será ignorado.`
          );
          continue;
        }
      }
    },
    deleteImage(index) {
      this.imagens.splice(index, 1);
      this.imagesEnterprise.splice(index, 1);
    },
    deleteDocument(index) {
      this.documentos.splice(index, 1);
      this.documentsEnterprise.splice(index, 1);
    },
    loadGoogleMaps() {
      return new Promise((resolve) => {
        if (typeof google === "undefined") {
          var script = document.createElement("script");
          script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_API_KEY}&libraries=places`;
          script.onload = resolve;
          document.head.appendChild(script);
        } else {
          resolve();
        }
      });
    },
    async initAutocomplete() {
      const input = document.getElementById("autocomplete");
      const options = {
        componentRestrictions: { country: "BR" },
        types: ["address"],
        fields: ["geometry", "formatted_address", "address_components"],
      };

      this.autocomplete = await new google.maps.places.Autocomplete(
        input,
        options
      );
      // Lidar com o evento de seleção
      this.autocomplete.addListener("place_changed", () => {
        this.place = this.autocomplete.getPlace();
        this.lat = this.place.geometry.location.lat();
        this.lng = this.place.geometry.location.lng();
        this.endereco = this.place.formatted_address;
        let state = "";
        let city = "";
        let neighborhood = "";

        this.place.address_components.forEach((component) => {
          if (component.types.includes("administrative_area_level_1")) {
            state = component.short_name;
          } else if (component.types.includes("administrative_area_level_2")) {
            city = component.long_name;
          } else if (component.types.includes("sublocality")) {
            neighborhood = component.long_name;
          } else if (
            neighborhood == "" &&
            component.types.includes("administrative_area_level_4")
          ) {
            neighborhood = component.long_name;
          }
        });

        this.endereco = this.place.formatted_address;
        this.estado = state;
        this.cidade = city;
        this.bairro = neighborhood;
        this.disabled = true;
        this.isKey++;
      });
    },
    async editItem(item) {
      this.editedItem = item;
      this.$emit("editar", item);
      this.modalShow = true;
      await this.loadGoogleMaps().then(() => {
        this.initAutocomplete();
      });
    },
    async saveEnterprise() {
      let enterprise = this.editedItem.empreendimento;
      enterprise.nome_empreendimento = this.nome_empreendimento;
      enterprise.tipo_empreendimento[0] = this.tipo_empreendimento;
      enterprise.segmento_atuacao[0] = this.segmento_atuacao;
      enterprise.custo_estimado_reais = parseFloat(this.custo_estimado_reais.replaceAll(/\D/g, "")) / 100;
      enterprise.vgv_reais = parseFloat(this.vgv_reais.replaceAll(/\D/g, "")) / 100;
      enterprise.fase_obra[0] = this.fase_obra;
      enterprise.avanco_geral_obra = String(this.avanco_geral_obra).replace(",", ".");
      enterprise.avanco_geral_obra = parseFloat(enterprise.avanco_geral_obra);
      enterprise.numero_unidades = parseInt(this.numero_unidades);
      enterprise.area_unidades_m2[0] = this.area_unidades_m2;
      enterprise.ticket_medio = parseFloat(this.ticket_medio.replaceAll(/\D/g, "")) / 100;
      enterprise.unidades_vendidas = parseInt(this.unidades_vendidas);
      enterprise.estoque = parseInt(this.estoque);
      enterprise.endereco = this.endereco;
      enterprise.regiao = this.getRegion(this.estado);
      enterprise.estado = this.estado;
      enterprise.cidade = this.cidade;
      enterprise.bairro = this.bairro;
      enterprise.imagens = this.imagens;
      enterprise.documentos = this.documentos;
      enterprise.coordenadas.latitude = this.lat;
      enterprise.coordenadas.longitude = this.lng;

      const idEmpreendimento = this.editedItem._id;
      try {
        await axios.put(
          `/api/vitrine/incorporadora/empreendimentos/${idEmpreendimento}`,
          enterprise,
          {
            headers: {
              id_cliente: this.$session.get("master").id_cliente,
            },
          }
        );

        this.modalShow = false;
      } catch (error) {
        console.log("Erro ao enviar para a API:", error);
      }
    },
    openConfirmDeleteModal(item) {
      this.currentItem = item;
      this.showConfirmModal = true;
    },
    confirmDelete() {
      this.showConfirmModal = false;
      this.$emit("excluir", this.currentItem);
    },
    getRegion(estado) {
      const estadosPorRegiao = [
        { regiao: "norte", estado: "AC" },
        { regiao: "norte", estado: "AP" },
        { regiao: "norte", estado: "AM" },
        { regiao: "norte", estado: "PA" },
        { regiao: "norte", estado: "RO" },
        { regiao: "norte", estado: "RR" },
        { regiao: "norte", estado: "TO" },
        { regiao: "nordeste", estado: "AL" },
        { regiao: "nordeste", estado: "BA" },
        { regiao: "nordeste", estado: "CE" },
        { regiao: "nordeste", estado: "MA" },
        { regiao: "nordeste", estado: "PB" },
        { regiao: "nordeste", estado: "PE" },
        { regiao: "nordeste", estado: "PI" },
        { regiao: "nordeste", estado: "RN" },
        { regiao: "nordeste", estado: "SE" },
        { regiao: "centro-oeste", estado: "DF" },
        { regiao: "centro-oeste", estado: "GO" },
        { regiao: "centro-oeste", estado: "MT" },
        { regiao: "centro-oeste", estado: "MS" },
        { regiao: "sudeste", estado: "ES" },
        { regiao: "sudeste", estado: "MG" },
        { regiao: "sudeste", estado: "RJ" },
        { regiao: "sudeste", estado: "SP" },
        { regiao: "sul", estado: "PR" },
        { regiao: "sul", estado: "RS" },
        { regiao: "sul", estado: "SC" },
      ]

      const selected = estadosPorRegiao.filter(
        (e) => e.estado.toLowerCase() === estado.toLowerCase()
      );

      return (selected[0] ? selected[0].regiao : "");
    }
  },
  mounted() {
    // const index = this.$parent.$children.indexOf(this);
    // if (index % 3 === 0) {
    //   this.availabilityNumber = 3;
    // } else if (index % 5 === 0) {
    //   this.showAvailabilityBadge = true;
    //   this.availabilityNumber = 4;
    // } else if (index % 3 === 0) {
    //   this.showAvailabilityBadge = true;
    //   this.availabilityNumber = 1;
    // }
  },
};
</script>

<style lang="scss" scoped>
.card-main {
  width: 330px;
  height: 380px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 1px 1px 4px 1px #00000033;
  padding: 12px;

  img {
    border-radius: 6px;
  }

  &:hover {
    box-shadow: 1px 1px 4px 1px #5d5d5dba;
  }
}

.card-main-map {
  width: 200px;
}

/deep/.leaflet-popup-content {
  margin: 15px !important;
  margin-top: 17px !important;
}

.icon {
  display: inline-block;
  width: 22px;
  height: 22px;
}

.icon-geo {
  background: url("../assets/geo.svg") center/cover;
  width: 19px;
  height: 19px;
  margin-right: 4px;
}

.type {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding-top: 8px;

  h6 {
    font-size: 16px;
    font-weight: 600;
    color: $main-blue;
  }
}

.address {
  display: flex;
  align-items: stretch;
  align-content: center;
  justify-content: flex-start;
  color: $neutral-80;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin: 4px 0;
}

.item-label {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;

  span {
    color: $neutral-70;
  }

  strong {
    color: $neutral-95;
  }
}

.image-container {
  position: relative;
  width: 100%;
  height: 145px;
}

.availability-badge {
  position: absolute;
  top: 11px;
  right: 11px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ed7554;
  color: white;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(50%, -50%);
}

.custom-tooltip {
  display: none;
  position: sticky;
  bottom: auto;
  left: auto;
  // transform: translateX(-70%) translateY(50px)
  padding: 6px 10px;
  width: 160px;
  height: auto;
  background-color: #ffffff;
  color: #343a40;
  z-index: 10000;
  font-size: 12px;
  font-weight: normal;
  line-height: auto;
  text-align: center;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.availability-badge:hover .custom-tooltip {
  opacity: 1;
  display: block;
}

.upload-icon {
  margin-right: 10px;
  font-size: 13px;
}

.icon-upload .text-icon-upload {
  font-size: 13px;
}

.upload-label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.file-browse {
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
  overflow: hidden;
  z-index: -1;
}

.text-icon-upload {
  font-size: 12px;
  width: 337px;
  height: 22px;
  font-family: SourceSansPro, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.4;
  color: #212529;
}

.icon-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  border-radius: 5px;
  border: solid 1px #868e96;
  background-color: #f8f9fa;
  flex-direction: column;
}

/* Container para as miniaturas */
.miniatura-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  padding: 10px 0;
}

.miniatura-container.imagens {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  padding: 10px 0;
}

.miniatura-container.documentos {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-end;
  padding: 10px 0;
}

.miniatura {
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  position: relative;
  width: 250px;
  height: 30px;
  padding: 5px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}

.miniatura img {
  max-width: 90%;
  max-height: 90%;
}

.miniatura.documento img {
  max-width: 30px;
  max-height: 20px;
}

.file-name {
  text-align: left;
  font-size: 0.8rem;
  color: #333;
  margin-top: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 5px;
}

.btn-delete {
  position: absolute;
  bottom: 0;
  right: 0;
  border: none;
  border-top-left-radius: 4px;
  background-color: transparent;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-delete img {
  width: 20px;
  height: 20px;
}

.icon-no-content {
  z-index: 1;
}
</style>
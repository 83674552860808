<template>
    <a class="sidebar-action" id="logout" @click="logout">
      <log-out-icon />
    </a>
</template>
  
  <script>
  import firebase from "firebase";
  import { LogOutIcon } from "vue-feather-icons";
  export default {
    components: {
      LogOutIcon,
    },
    props: {
      variant: {
        type: String,
        default: "primary",
        validator: (value) => {
          return ["primary", "secondary"].indexOf(value) !== -1;
        },
      },
      redirect: {
        type: String,
        default: "/",
      },
    },
    data() {
        return {
            
        }
    },
    mounted() {
      this.$el.style.color = this.variant === "primary" ? "#fff" : "#722381";
  
    },
    methods: {
      logout: function () {
        this.$analytics.logEvent("logout", {
          method: "Google",
          event_category: "logout",
        });
        this.$session.destroy();
        this.$router.push({ redirect: (window.location.href = this.redirect) });
        firebase.auth().signOut();
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  
  .sidebar-action {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    cursor: pointer;
  }   
  </style>
  
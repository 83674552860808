import axios from "axios";

class APIVitrine {
  constructor() { }

  async uploadFile(file) {
    try {
      const uninterceptedAxiosInstance = axios.create();
      const formData = new FormData();
      formData.append("file", file);
      const response = await uninterceptedAxiosInstance.post(
        "/api/vitrine/uploadImage",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response.data.upload;
    } catch (e) {
      console.error(error);
    }
  }
  async getTypedEnterprises(type) {
    try {
      const master = this.$session.get("master");
      const response = await axios.get(
        `/api/vitrine/fundo_investimento/painel_empreendimentos/${type}`, {
          headers: { id_cliente: master.id_cliente },
      });

      return response.data;
    } catch (e) {
      console.error(error);
    }
  }

  async getEnterprises() {
    try {
      const master = this.$session.get("master");
      const response = await axios.get(
        `/api/vitrine/incorporadora/empreendimentos`, {
          headers: { id_cliente: master.id_cliente },
      });

      return response.data
    } catch (error) {
      console.error(error);
    }
  }

}

export default new APIVitrine();
